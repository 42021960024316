import PrismicPage from "~/views/PrismicPage";

function HelpOverviewPage(props) {
  return <PrismicPage {...props} />;
}

HelpOverviewPage.getInitialProps = async (ctx) => {
  try {
    ctx.query.pageUID = "help";

    const viewProps = await PrismicPage.getInitialProps(ctx);

    // return the props for the Page component
    return {
      ...viewProps,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default HelpOverviewPage;

// TODO: V2 using TypeScript

/* import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { Document } from 'prismic-javascript/types/documents';
import { query as prismic, queryDocument } from 'external/v2/prismic';
import { CommonPageProps, PageDocument } from 'external/v2/types';
import styled from 'styled-components';
import Error from 'views/Error';
import Block, { BlockContent, BlockTopSpacer } from 'components/Block';

interface PrismicPageProps extends CommonPageProps {
  document: PageDocument;
}

function HelpOverviewPage(props: PrismicPageProps) {
  if (props.error) return <Error {...props.error} />;

  const { document } = props;

  return (
    <>
      <Head>
        <title>{document.content.page_title} | AIAIAI</title>
      </Head>
      <Block
        backgroundColor="white"
        noSpacingTop
        style={{ paddingTop: '5rem' }}
      >
        <BlockTopSpacer spacing="topBar" />
        <BlockContent style={{ minHeight: '80vh' }}>
          {JSON.stringify(document, null, 2)}
        </BlockContent>
      </Block>
    </>
  );
}

export const getServerSideProps = async ({ query, res }) => {
  const document = await prismic.page('help');

  console.log(document);

  if (!document) {
    res.statusCode = 404;
    //context.res.end();
    return {
      props: {
        error: {
          statusCode: 404,
          message: 'Page not found'
        }
      }
    };
  }

  return {
    props: {
      document
    }
  };
};

export default HelpOverviewPage;
*/
